import ContactPage from 'components/pages/contact/ContactPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Kontakt | FINTEQ'
			keywords='kontakt, telefon, e-mail,  pomoc, pytanie, problem'
			description='Masz pytania, szukasz pomocy, chcesz zgłosić problem – zadzwoń lub napisz do nas.'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<ContactPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
